import './App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import {  Route , Routes} from 'react-router-dom';
import AllProperty from './Component/AllProperties/AllProperty';
import HomeScreen from './Component/Home/Home';
import Contect from './Component/Contect/Contect';
import Details from './Component/DetailsAll/Details';
import Login from './Component/Login/Login';




function App() {
  return (
   <>
   <HashRouter>
  <div className="App">
    
      
          <Routes>
          <Route  path='/' element={<HomeScreen/>}/>
    <Route path='/AllProperty' element={<AllProperty/>}/>
    <Route path='/Details' element={<Details/>}/>
    <Route path='/Contect' element={<Contect/>}/>
    <Route path='/Login' element={<Login/>}/>


          </Routes>
       
      
      
  
  
  </div>
</HashRouter>
   </>
  );
}

export default App;
