import React from "react";

const contect = () =>{

return(
    <div>

{/* <div class="preloader-area text-center position-fixed top-0 bottom-0 start-0 end-0" id="preloader">
    <div class="loader position-absolute start-0 end-0">
        <img src="images/favicon.png" alt="favicon"/>
        <div class="waviy position-relative">
            <span class="d-inline-block">D</span>
            <span class="d-inline-block">R</span>
            <span class="d-inline-block">E</span>
            <span class="d-inline-block">M</span>
            <span class="d-inline-block">K</span>
            <span class="d-inline-block">O</span>
            <span class="d-inline-block">T</span>
            <span class="d-inline-block">H</span>
            <span class="d-inline-block">I</span>

        </div>
    </div>
</div> */}
  <div class="top-header-area">
      <div class="container-fluid">
          <div class="row justify-content-center align-items-center">
              <div class="col-lg-7 col-md-7">
                  <ul class="top-header-info-with-social">
                      <li>
                          <div class="social">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </li>
                      <li>
                          <div class="mail-info">
                              <i class="ri-mail-line"></i>
                              <a href="mailto:dreamkothi@gmail.com"><span class="__cf_email__">dreamkothi@gmail.com</span></a>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-5 col-md-5 text-end">
                  <div class="top-header-call-info">
                      <i class="ri-phone-line"></i>
                      <a href="tel:+91 9694949481">+91 9694949481</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <nav class="navbar navbar-expand-xl" id="navbar">
      <div class="container-fluid">
          <a class="navbar-brand" href="index.html">
               {/* <img src="images/logo.png" alt="logo">  */}

              <b>DREAMKOTHI</b>
          </a>
          <form class="search-form">
              <input type="search" class="search-field" placeholder="Search property"/>
              <button type="submit">
                  <i class="ri-search-line"></i>
              </button>
          </form>
          <button class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas">
              <span class="burger-menu">
                  <span class="top-bar"></span>
                  <span class="middle-bar"></span>
                  <span class="bottom-bar"></span>
              </span>
          </button>
          <div class="collapse navbar-collapse">
              <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link active">
                          Home
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      {/* <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a href="index.html" class="nav-link active">HOME</a>
                          </li>
                        
                      </ul> */}
                  </li>
                  {/* <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                          Pages
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a href="about-us.html" class="nav-link">About Us</a>
                          </li>
                          <li class="nav-item">
                              <a href="agents.html" class="nav-link">Agents</a>
                          </li>
                          <li class="nav-item">
                              <a href="agent-profile.html" class="nav-link">Agent Profile</a>
                          </li>
                          <li class="nav-item">
                              <a href="pricing.html" class="nav-link">Pricing</a>
                          </li>
                          <li class="nav-item">
                              <a href="what-we-do.html" class="nav-link">What We Do</a>
                          </li>
                          <li class="nav-item">
                              <a href="neighborhood.html" class="nav-link">Neighborhood</a>
                          </li>
                          <li class="nav-item">
                              <a href="inquiry-form.html" class="nav-link">Inquiry Form</a>
                          </li>
                          <li class="nav-item">
                              <a href="gallery.html" class="nav-link">Gallery</a>
                          </li>
                          <li class="nav-item">
                              <a href="customers-review.html" class="nav-link">Customers Review</a>
                          </li>
                          <li class="nav-item">
                              <a href="faq.html" class="nav-link">FAQ</a>
                          </li>
                          <li class="nav-item">
                              <a href="my-account.html" class="nav-link">My Account</a>
                          </li>
                          <li class="nav-item">
                              <a href="privacy-policy.html" class="nav-link">Privacy Policy</a>
                          </li>
                          <li class="nav-item">
                              <a href="terms-conditions.html" class="nav-link">Terms &amp; Conditions</a>
                          </li>
                          <li class="nav-item">
                              <a href="not-found.html" class="nav-link">404 Error Page</a>
                          </li>
                      </ul> 
                  </li> */}
                  <li class="nav-item">
                      <a href="#/AllProperty" class="dropdown-toggle nav-link">
                          Property
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          
                        
                          
                          <li class="nav-item">
                              <a href="#/Details" class="nav-link">Property Details</a>
                          </li>
                        
                         
                      </ul>
                  </li>
                 
                  <li class="nav-item">
                      <a href="contact-us.html" class="nav-link">Contact Us</a>
                  </li>
              </ul>
              <div class="others-option d-flex align-items-center">
                  <div class="option-item">
                      <div class="user-info">
                          <a href="my-account.html">Log In / Register</a>
                      </div>
                  </div>
                  <div class="option-item">
                      <a href="contact-us.html" class="default-btn">Contact Us</a>
                  </div>
              </div>
          </div>
      </div>
  </nav>

  <div class="responsive-navbar offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvas">
      <div class="offcanvas-header">
          <a href="index.html" class="logo d-inline-block">
              {/* <img src="images/logo.png" alt="logo"> */}
          </a>
          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" class="close-btn">
              <i class="ri-close-line"></i>
          </button>
      </div>
      <div class="offcanvas-body">
          <div class="accordion" id="navbarAccordion">
              <div class="accordion-item">
                  <button class="accordion-button collapsed active" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Home
                  </button>
                  <div id="collapseOne" class="accordion-collapse collapse " data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a class="accordion-link active" href="index.html">
                                       Demo
                                  </a>
                              </div>
                           
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Property
                  </button>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a href="property-grid.html" class="accordion-link">
                                      Property All
                                  </a>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <a class="accordion-button without-icon" href="contact-us.html">
                      Contact Us
                  </a>
              </div>
          </div>
          <div class="others-option">
              <div class="option-item">
                  <div class="user-info">
                      <a href="my-account.html">Log In / Register</a>
                  </div>
              </div>
              <div class="option-item">
                  <a href="contact-us.html" class="default-btn">Contact Us</a>
              </div>
              <div class="option-item">
                  <form class="search-form">
                      <input type="search" class="search-field" placeholder="Search property"/>
                      <button type="submit">
                          <i class="ri-search-line"></i>
                      </button>
                  </form>
              </div>
          </div>
      </div>
  </div>
        
        <div class="page-banner-area">
            <div class="container">
                <div class="page-banner-content">
                    <h2>Contact Us</h2>
                    <ul class="list">
                        <li>
                            <a href="index.html">Home</a>
                        </li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="contact-area ptb-120">
            <div class="container">
                <div class="row justify-content-center" data-cues="slideInUp">
                    <div class="col-lg-4 col-md-5">
                        <div class="contact-info-box">
                            <div class="box">
                                <div class="icon">
                                    <i class="ri-map-pin-line"></i>
                                </div>
                                <div class="info">
                                    <h4>Our Location</h4>
                                    <span>45/15 New alsala Avenew Booston town, Austria</span>
                                </div>
                            </div>
                            <div class="box">
                                <div class="icon">
                                    <i class="ri-phone-line"></i>
                                </div>
                                <div class="info">
                                    <h4>Phone Number</h4>
                                    <span>
                                        <a href="tel:00201068710594">+(002) 0106-8710-594</a>
                                    </span>
                                    <span>
                                        <a href="tel:00201068710588">+(002) 0106-8710-588</a>
                                    </span>
                                </div>
                            </div>
                            <div class="box">
                                <div class="icon">
                                    <i class="ri-mail-send-line"></i>
                                </div>
                                <div class="info">
                                    <h4>Our Email</h4>
                                    <span>
                                        <a href="/cdn-cgi/l/email-protection#70030500001f0204191e161f30111e141f02115e131f1d"><span class="__cf_email__" data-cfemail="3a494f4a4a55484e53545c557a5b545e55485b14595557">[email�&nbsp;protected]</span></a>
                                    </span>
                                    <span>
                                        <a href="/cdn-cgi/l/email-protection#1a7975746e7b796e5a73747c7534797577"><span class="__cf_email__" data-cfemail="3a5955544e5b594e7a53545c5514595557">[email�&nbsp;protected]</span></a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-7">
                        <div class="contact-wrap-form">
                            <h3>Get In Touch</h3>
                            <form>
                                <div class="form-group">
                                    <label>Your Name</label>
                                    <input type="text" class="form-control" placeholder="Enter your name"/>
                                    <div class="icon">
                                        <i class="ri-user-3-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" placeholder="Enter email address"/>
                                    <div class="icon">
                                        <i class="ri-mail-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Your Phone</label>
                                    <input type="phone" class="form-control" placeholder="Enter your phone"/>
                                    <div class="icon">
                                        <i class="ri-phone-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Your Subject</label>
                                    <input type="text" class="form-control" placeholder="Enter your subject"/>
                                    <div class="icon">
                                        <i class="ri-file-line"></i>
                                    </div>
                                </div>
                                <div class="form-group extra-top">
                                    <label>Your Message</label>
                                    <textarea class="form-control" placeholder="Your message here"></textarea>
                                    <div class="icon">
                                        <i class="ri-message-2-line"></i>
                                    </div>
                                </div>
                                <div class="contact-btn">
                                    <button type="submit" class="default-btn">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="map-area">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2751228.88121311!2d10.706860407488707!3d47.66991201325446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d079b259d2a7f%3A0x1012d47bdde4c1af!2sAustria!5e0!3m2!1sen!2sbd!4v1703576701537!5m2!1sen!2sbd"></iframe>
        </div>
        
        <footer class="footer-area pt-120">
      <div class="container">
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-xl-3 col-md-12">
                  <div class="single-footer-widget pe-3">
                      <div class="widget-logo">
                          <a href="index.html">
                               {/* <img src="images/logo2.png" alt="logo2"> */}
                              <b class="text-white">DREAMKOTHI</b>
                          </a>
                      </div>
                      <p>Lorem ipsum dolor sit amet, elit dollar consectetur adipiscing elit. Diam lectus purus ultricies neque.</p>
                      <div class="widget-social">
                          <a href="https://www.facebook.com/" target="_blank">
                              <i class="ri-facebook-fill"></i>
                          </a>
                          <a href="https://twitter.com/" target="_blank">
                              <i class="ri-twitter-x-line"></i>
                          </a>
                          <a href="https://www.instagram.com/" target="_blank">
                              <i class="ri-instagram-fill"></i>
                          </a>
                          <a href="https://bd.linkedin.com/" target="_blank">
                              <i class="ri-linkedin-fill"></i>
                          </a>
                          <a href="https://www.youtube.com/" target="_blank">
                              <i class="ri-youtube-line"></i>
                          </a>
                          <a href="https://www.pinterest.com/" target="_blank">
                              <i class="ri-pinterest-line"></i>
                          </a>
                      </div>
                  </div>
              </div>
              <div class="col-xl-9 col-md-12">
                  <div class="row justify-content-center" data-cues="slideInUp">
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Company</h3>
                              <ul class="custom-links">
                                  <li><a href="about-us.html">About Us</a></li>
                                  <li><a href="contact-us.html">Contact Us</a></li>
                                  <li><a href="customers-review.html">Our Reviews</a></li>
                                  <li><a href="terms-conditions.html">Terms &amp; Conditions</a></li>
                                  <li><a href="privacy-policy.html">Privacy Policy</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Resources</h3>
                              <ul class="custom-links">
                                  <li><a href="property-grid.html">Apartments</a></li>
                                  <li><a href="property-grid.html">Villa</a></li>
                                  <li><a href="property-grid.html">Sell or Buy</a></li>
                                  <li><a href="property-grid.html">New Apartment</a></li>
                                  <li><a href="agents.html">Our Agents</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Quick Links</h3>
                              <ul class="custom-links">
                                  <li><a href="pricing.html">Pricing</a></li>
                                  <li><a href="what-we-do.html">What We Do</a></li>
                                  <li><a href="customers-review.html">Testimonial</a></li>
                                  <li><a href="blog-grid.html">Blog</a></li>
                                  <li><a href="neighborhood.html">Neighborhood</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget">
                              <h3>Contact Info</h3>
                              <ul class="info-list">
                                  <li>
                                      <span>Address: </span>
                                      <a href="https://maps.app.goo.gl/JHGxiX1hVZQEDi1B6">
                                      SHOP NO S-45 JANAKPURI COLONY NEAR HATHOJ STAND,JHOTWARA, JAIPUR, RAJASTHAN - 302012
                                      </a>
                                       
                                  </li>
                                  <li>
                                      <span>Email: </span> 
                                      <a href="mailto:dreamkothi@gmail.com">dreamkothi@gmail.com</a>
                                  </li>
                                  <li>
                                      <span>Phone:</span>
                                      <a href="tel:+91 9694949481">+91 9694949481</a>
                                  </li>
                                 
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="copyright-area">
              <p>
                  Copyright <span>Dreamkothi</span> <a href="https://envytheme.com/" target="_blank"></a>
              </p>
          </div>
      </div>
  </footer>
        
        <button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
            <i class="ri-arrow-up-s-line"></i>
        </button>
    
    
    
    </div>
)



}

export default contect;