import React from "react";
import bed from '../../images/bed.svg'
import bathroom from '../../images/bathroom.svg'
import parking from '../../images/parking.svg'
import area from '../../images/area.svg'
import Logo from "../../images/Logo.webp"
import bed_1 from '../../images/bed_1.svg'
import bathroom_1 from '../../images/bathroom_1.svg'
import parking_1 from '../../images/parking_1.svg'
import area_1 from '../../images/arrow_1.svg'
import agents1 from '../../images/agents1.jpg'
import agents2 from '../../images/agents2.jpg'
import agents3 from '../../images/agents3.jpg'
import agents4 from '../../images/agents4.jpg'
import blog1 from '../../images/blog1.jpg'
import blog2 from '../../images/blog2.jpg'
import blog3 from '../../images/blog3.jpg'
import blog4 from '../../images/blog4.jpg'








import banner1 from '../../images/banner1.jpg'
import banner2 from '../../images/banner2.jpg'
import banner3 from '../../images/banner3.jpg'
import category1  from '../../images/category1.png'
import category2  from '../../images/category2.png'
import category3  from '../../images/category3.png'
import category4  from '../../images/category4.png'
import category5  from '../../images/category5.png'
import category6  from '../../images/category6.png'
import category7  from '../../images/category7.png'
import category8  from '../../images/category8.png'
import properties1  from '../../images/properties1.jpg'
import properties2  from '../../images/properties2.jpg'
import properties3  from '../../images/properties3.jpg'
import properties4  from '../../images/properties4.jpg'
import properties5  from '../../images/properties5.jpg'
import properties6  from '../../images/properties6.jpg'

import properties7  from '../../images/properties7.jpg'
import properties8  from '../../images/properties8.jpg'

import properties9  from '../../images/properties9.jpg'
import user1 from '../../images/user1.png'
import user2 from '../../images/user2.png'

import user3 from '../../images/user3.png'

import user4 from '../../images/user4.png'

import arrow02 from '../../images/arrow.svg'

import arrow from '../../images/area_1.svg'
import user5 from '../../images/user5.png'
import user6 from '../../images/user6.png'


























const HomeScreen = () =>{

return(
    <section>

{/* <div class="preloader-area text-center position-fixed top-0 bottom-0 start-0 end-0" id="preloader">
    <div class="loader position-absolute start-0 end-0">
        <img src="images/favicon.png" alt="favicon"/>
        <div class="waviy position-relative">
            <span class="d-inline-block">D</span>
            <span class="d-inline-block">R</span>
            <span class="d-inline-block">E</span>
            <span class="d-inline-block">M</span>
            <span class="d-inline-block">K</span>
            <span class="d-inline-block">O</span>
            <span class="d-inline-block">T</span>
            <span class="d-inline-block">H</span>
            <span class="d-inline-block">I</span>

        </div>
    </div>
</div> */}
  <div class="top-header-area">
      <div class="container-fluid">
          <div class="row justify-content-center align-items-center">
              <div class="col-lg-7 col-md-7">
                  <ul class="top-header-info-with-social">
                      <li>
                          <div class="social">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </li>
                      <li>
                          <div class="mail-info">
                              <i class="ri-mail-line"></i>
                              <a href="mailto:dreamkothi@gmail.com"><span class="__cf_email__">dreamkothi@gmail.com</span></a>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-5 col-md-5 text-end">
                  <div class="top-header-call-info">
                      <i class="ri-phone-line"></i>
                      <a href="tel:+91 9694949481">+91 9694949481</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <nav class="navbar navbar-expand-xl" id="navbar">
      <div class="container-fluid">
          <a class="navbar-brand" href="index.html">
               {/* <img src="images/logo.png" alt="logo">  */}

              <b>DREAMKOTHI</b>
          </a>
          <form class="search-form">
              <input type="search" class="search-field" placeholder="Search property"/>
              <button type="submit">
                  <i class="ri-search-line"></i>
              </button>
          </form>
          <button class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas">
              <span class="burger-menu">
                  <span class="top-bar"></span>
                  <span class="middle-bar"></span>
                  <span class="bottom-bar"></span>
              </span>
          </button>
          <div class="collapse navbar-collapse">
              <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                      <a href="/" class="dropdown-toggle nav-link active">
                          Home
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      {/* <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a href="index.html" class="nav-link active">HOME</a>
                          </li>
                        
                      </ul> */}
                  </li>
                  <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                          About
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          
                          {/* <li class="nav-item">
                              <a href="my-account.html" class="nav-link">My Account</a>
                          </li> */}
                          <li class="nav-item">
                              <a href="privacy-policy.html" class="nav-link">Privacy Policy</a>
                          </li>
                          <li class="nav-item">
                              <a href="terms-conditions.html" class="nav-link">Terms &amp; Conditions</a>
                          </li>
                         
                      </ul> 
                  </li>
                  <li class="nav-item">
                      <a href="#/AllProperty" class="dropdown-toggle nav-link">
                          Property
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          
                        
                          
                          <li class="nav-item">
                              <a href="#/Details" class="nav-link">Property Details</a>
                          </li>
                        
                         
                      </ul>
                  </li>
                 
                  <li class="nav-item">
                      <a href="#/Contect" class="nav-link">Contact Us</a>
                  </li>
              </ul>
              <div class="others-option d-flex align-items-center">
                  <div class="option-item">
                      <div class="user-info">
                          <a href="#/Login">Log In / Register</a>
                      </div>
                  </div>
                  <div class="option-item">
                      <a href="#/Contect" class="default-btn">Contact Us</a>
                  </div>
              </div>
          </div>
      </div>
  </nav>

  <div class="responsive-navbar offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvas">
      <div class="offcanvas-header">
          <a href="index.html" class="logo d-inline-block">
              {/* <img src="images/logo.png" alt="logo"> */}
          </a>
          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" class="close-btn">
              <i class="ri-close-line"></i>
          </button>
      </div>
      <div class="offcanvas-body">
          <div class="accordion" id="navbarAccordion">
              <div class="accordion-item">
                  <button class="accordion-button collapsed active" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Home
                  </button>
                  <div id="collapseOne" class="accordion-collapse collapse " data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a class="accordion-link active" href="/">
                                       Home
                                  </a>
                              </div>
                           
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Property
                  </button>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a href="#/AllProperty" class="accordion-link">
                                      Property All
                                  </a>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <a class="accordion-button without-icon" href="#/Contect">
                      Contact Us
                  </a>
              </div>
          </div>
          <div class="others-option">
              <div class="option-item">
                  <div class="user-info">
                      <a href="#/Login">Log In / Register</a>
                  </div>
              </div>
              <div class="option-item">
                  <a href="#/Contect" class="default-btn">Contact Us</a>
              </div>
              <div class="option-item">
                  <form class="search-form">
                      <input type="search" class="search-field" placeholder="Search property"/>
                      <button type="submit">
                          <i class="ri-search-line"></i>
                      </button>
                  </form>
              </div>
          </div>
      </div>
  </div>

  <div class="main-banner-area">
      <div class="container-fluid">
          <div class="row justify-content-center align-items-center">
              <div class="col-xl-6 col-md-12" data-cues="slideInLeft">
                  <div class="main-banner-content">
                      <span class="sub">Your Pathway to Home Sweet Home.</span>
                      <h1>More than Property, We Offer Possibilities</h1>
                      <div class="search-info-tabs">
                          <ul class="nav nav-tabs" id="search_tab" role="tablist">
                              <li class="nav-item"><a class="nav-link active" id="sell-tab" data-bs-toggle="tab" href="#sell" role="tab" aria-controls="sell">Sell</a></li>
                              <li class="nav-item"><a class="nav-link" id="rent-tab" data-bs-toggle="tab" href="#rent" role="tab" aria-controls="rent">Rent</a></li>
                              <li class="nav-item"><a class="nav-link" id="invest-tab" data-bs-toggle="tab" href="#invest" role="tab" aria-controls="invest">Buy</a></li>
                          </ul>
                          <div class="tab-content" id="search_tab_content">
                              <div class="tab-pane fade show active" id="sell" role="tabpanel">
                                  <form class="search-form">
                                      <div class="row justify-content-center align-items-end">
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Looking For</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">Property type</option>
                                                      <option value="1">Multifamily</option>
                                                      <option value="2">Detached house</option>
                                                      <option value="3">Industrial</option>
                                                      <option value="4">Townhouse</option>
                                                      <option value="5">Apartment</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Location</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">All cities</option>
                                                      <option value="1">Liverpool</option>
                                                      <option value="2">Bristol</option>
                                                      <option value="3">Nottingham</option>
                                                      <option value="4">Leicester</option>
                                                      <option value="5">Coventry</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Your Price</label>
                                                  <input type="text" class="form-control" placeholder="Max price"/>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Min Lot size</label>
                                                  <input type="text" class="form-control" placeholder="Property lot size"/>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Status</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">Property status</option>
                                                      <option value="1">Active (55)</option>
                                                      <option value="2">Open House (65)</option>
                                                      <option value="3">Hot Offer (45)</option>
                                                      <option value="4">Sold (78)</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <button type="submit" class="default-btn">
                                                      <i class="ri-search-2-line"></i>
                                                      Search Property
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                              <div class="tab-pane fade" id="rent" role="tabpanel">
                                  <form class="search-form">
                                      <div class="row justify-content-center align-items-end">
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Looking For</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">Property type</option>
                                                      <option value="1">Multifamily</option>
                                                      <option value="2">Detached house</option>
                                                      <option value="3">Industrial</option>
                                                      <option value="4">Townhouse</option>
                                                      <option value="5">Apartment</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Location</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">All cities</option>
                                                      <option value="1">Liverpool</option>
                                                      <option value="2">Bristol</option>
                                                      <option value="3">Nottingham</option>
                                                      <option value="4">Leicester</option>
                                                      <option value="5">Coventry</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Your Price</label>
                                                  <input type="text" class="form-control" placeholder="Max price"/>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Min Lot size</label>
                                                  <input type="text" class="form-control" placeholder="Property lot size"/>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Status</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">Property status</option>
                                                      <option value="1">Active (55)</option>
                                                      <option value="2">Open House (65)</option>
                                                      <option value="3">Hot Offer (45)</option>
                                                      <option value="4">Sold (78)</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <button type="submit" class="default-btn">
                                                      <i class="ri-search-2-line"></i>
                                                      Search Property
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                              <div class="tab-pane fade" id="invest" role="tabpanel">
                                  <form class="search-form">
                                      <div class="row justify-content-center align-items-end">
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Looking For </label>
                                                  <select class="form-select form-control">
                                                      <option selected="">Property type</option>
                                                      <option value="1">Multifamily</option>
                                                      <option value="2">Detached house</option>
                                                      <option value="3">Industrial</option>
                                                      <option value="4">Townhouse</option>
                                                      <option value="5">Apartment</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Location</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">All cities</option>
                                                      <option value="1">Liverpool</option>
                                                      <option value="2">Bristol</option>
                                                      <option value="3">Nottingham</option>
                                                      <option value="4">Leicester</option>
                                                      <option value="5">Coventry</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Your Price</label>
                                                  <input type="text" class="form-control" placeholder="Max price"/>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Min Lot size</label>
                                                  <input type="text" class="form-control" placeholder="Property lot size"/>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <label>Status</label>
                                                  <select class="form-select form-control">
                                                      <option selected="">Property status</option>
                                                      <option value="1">Active (55)</option>
                                                      <option value="2">Open House (65)</option>
                                                      <option value="3">Hot Offer (45)</option>
                                                      <option value="4">Sold (78)</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div class="col-lg-4 col-md-6">
                                              <div class="form-group">
                                                  <button type="submit" class="default-btn">
                                                      <i class="ri-search-2-line"></i>
                                                      Search Property
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-6 col-md-12" data-cues="fadeIn">
                  <div class="swiper main-banner-image-slider">
                      <div class="swiper-wrapper">
                          <div class="swiper-slide">
                              <div class="main-banner-image">
                                  <img src={banner1} alt="image"/>

                              </div>
                          </div>
                          <div class="swiper-slide">
                              <div class="main-banner-image">
                                  <img src={banner2} alt="image"/>

                              </div>
                          </div>
                          <div class="swiper-slide">
                              <div class="main-banner-image">
                                  <img src={banner3} alt="image"/>

                              </div>
                          </div>
                      </div>
                      <div class="main-banner-image-pagination"></div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="category-area pt-120 pb-95">
      <div class="container">
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category1} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">Residential</a>
                          </h3>
                          <span>(26 Properties)</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category2} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">Commercial</a>
                          </h3>
                          <span>(33 Properties)</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category3} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">Vacation &amp; Resort</a>
                          </h3>
                          <span>(37 Properties)</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category4} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">The Land</a>
                          </h3>
                          <span>(54 Properties)</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category5} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">New Construction</a>
                          </h3>
                          <span>(123 Properties)</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category6} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">Luxury Estate</a>
                          </h3>
                          <span>(355 Properties)</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category7} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">Eco-Friendly</a>
                          </h3>
                          <span>(89 Properties)</span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="category-card">
                      <div class="image">
                          <img src={category8} alt="image"/>

                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-grid.html">Historic Properties</a>
                          </h3>
                          <span>(17 Properties)</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="properties-area pb-95">
      <div class="container">
          <div class="section-title text-center" data-cues="slideInUp">
              <h2>Latest Properties</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare venenatis, in. Pharetra iaculis consectetur.</p>
          </div>
          <div class="properties-information-tabs">
              <ul class="nav nav-tabs" id="properties_tab" role="tablist" data-cue="slideInUp">
                  <li class="nav-item"><a class="nav-link active" id="for-sale-tab" data-bs-toggle="tab" href="#for-sale" role="tab" aria-controls="for-sale">For Sale</a></li>
                  <li class="nav-item"><a class="nav-link" id="houses-tab" data-bs-toggle="tab" href="#houses" role="tab" aria-controls="houses">Houses</a></li>
                  <li class="nav-item"><a class="nav-link" id="villas-tab" data-bs-toggle="tab" href="#villas" role="tab" aria-controls="villas">Villas</a></li>
                  <li class="nav-item"><a class="nav-link" id="rental-tab" data-bs-toggle="tab" href="#rental" role="tab" aria-controls="rental">Rental</a></li>
                  <li class="nav-item"><a class="nav-link" id="apartment-tab" data-bs-toggle="tab" href="#apartment" role="tab" aria-controls="apartment">Apartment</a></li>
                  <li class="nav-item"><a class="nav-link" id="condos-tab" data-bs-toggle="tab" href="#condos" role="tab" aria-controls="condos">Condos</a></li>
                  <li class="nav-item"><a class="nav-link" id="commercial-tab" data-bs-toggle="tab" href="#commercial" role="tab" aria-controls="commercial">Commercial</a></li>
              </ul>
              <div class="tab-content" id="properties_tab_content">
                  <div class="tab-pane fade show active" id="for-sale" role="tabpanel">
                      <div class="row justify-content-center" data-cues="slideInUp">
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                      <img src={properties1} alt="image" />

                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />

                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />

                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />

                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Vacation Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$95,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user1} alt="image" />
                                              <a href="agent-profile.html">Thomas Klarck</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties2} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Industrial Spaces</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$55,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user2} alt="image" />
                                              <a href="agent-profile.html">Walter White</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties3} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Single-Family Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$77,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user3} alt="image" />
                                              <a href="agent-profile.html">Jane Ronan</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties4} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Newly Built Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$33,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user4} alt="image" />
                                              <a href="agent-profile.html">Jack Smith</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties5} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Senior Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$65,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user5} alt="image" />
                                              <a href="agent-profile.html">Jenny Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties6} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Luxury Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$89,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user6} alt="image" />
                                              <a href="agent-profile.html">Bella Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="houses" role="tabpanel">
                      <div class="row justify-content-center" data-cue="slideInUp">
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties1} alt="image" />
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Vacation Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$95,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              
                                              <img src={user1} alt="image" />
                                              <a href="agent-profile.html">Thomas Klarck</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties2} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Industrial Spaces</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$55,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user2} alt="image" />
                                              <a href="agent-profile.html">Walter White</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties3} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Single-Family Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$77,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user3} alt="image" />
                                              <a href="agent-profile.html">Jane Ronan</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties4} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Newly Built Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$33,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user4} alt="image" />
                                              <a href="agent-profile.html">Jack Smith</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties5} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Senior Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$65,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user5} alt="image" />
                                              <a href="agent-profile.html">Jenny Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties6} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Luxury Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$89,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user6} alt="image" />
                                              <a href="agent-profile.html">Bella Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="villas" role="tabpanel">
                      <div class="row justify-content-center" data-cue="slideInUp">
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                      <img src={properties1} alt="image" />

                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Vacation Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$95,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user1} alt="image" />
                                              <a href="agent-profile.html">Thomas Klarck</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties2} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Industrial Spaces</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$55,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user2} alt="image" />
                                              <a href="agent-profile.html">Walter White</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties3} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Single-Family Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$77,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user3} alt="image" />
                                              <a href="agent-profile.html">Jane Ronan</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties4} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Newly Built Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$33,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user4} alt="image" />
                                              <a href="agent-profile.html">Jack Smith</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties5} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Senior Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$65,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user5} alt="image" />
                                              <a href="agent-profile.html">Jenny Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties6} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Luxury Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$89,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user6} alt="image" />
                                              <a href="agent-profile.html">Bella Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="rental" role="tabpanel">
                      <div class="row justify-content-center" data-cue="slideInUp">
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                      <img src={properties1} alt="image" />

                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Vacation Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$95,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user1} alt="image" />
                                              <a href="agent-profile.html">Thomas Klarck</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties2} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Industrial Spaces</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$55,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user2} alt="image" />
                                              <a href="agent-profile.html">Walter White</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties3} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Single-Family Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$77,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user3} alt="image" />
                                              <a href="agent-profile.html">Jane Ronan</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties4} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Newly Built Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$33,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user4} alt="image" />
                                              <a href="agent-profile.html">Jack Smith</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties5} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Senior Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$65,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user5} alt="image" />
                                              <a href="agent-profile.html">Jenny Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties6} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Luxury Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$89,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user6} alt="image" />
                                              <a href="agent-profile.html">Bella Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="apartment" role="tabpanel">
                      <div class="row justify-content-center" data-cue="slideInUp">
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                      <img src={properties1} alt="image" />

                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Vacation Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$95,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user1} alt="image" />
                                              <a href="agent-profile.html">Thomas Klarck</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties2} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Industrial Spaces</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$55,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user2} alt="image" />
                                              <a href="agent-profile.html">Walter White</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties3} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Single-Family Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$77,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user3} alt="image" />
                                              <a href="agent-profile.html">Jane Ronan</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties4} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Newly Built Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$33,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user4} alt="image" />
                                              <a href="agent-profile.html">Jack Smith</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties5} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Senior Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$65,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user5} alt="image" />
                                              <a href="agent-profile.html">Jenny Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties6} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Luxury Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$89,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user6} alt="image" />
                                              <a href="agent-profile.html">Bella Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="condos" role="tabpanel">
                      <div class="row justify-content-center" data-cue="slideInUp">
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                      <img src={properties1} alt="image" />

                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Vacation Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$95,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user1} alt="image" />
                                              <a href="agent-profile.html">Thomas Klarck</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties2} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Industrial Spaces</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$55,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user2} alt="image" />
                                              <a href="agent-profile.html">Walter White</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties3} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Single-Family Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$77,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user3} alt="image" />
                                              <a href="agent-profile.html">Jane Ronan</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties4} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Newly Built Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$33,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user4} alt="image" />
                                              <a href="agent-profile.html">Jack Smith</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties5} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Senior Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$65,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user5} alt="image" />
                                              <a href="agent-profile.html">Jenny Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties6} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Luxury Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$89,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user6} alt="image" />
                                              <a href="agent-profile.html">Bella Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="commercial" role="tabpanel">
                      <div class="row justify-content-center" data-cue="slideInUp">
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                      <img src={properties1} alt="image" />

                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Vacation Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$95,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user1} alt="image" />
                                              <a href="agent-profile.html">Thomas Klarck</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties2} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Industrial Spaces</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$55,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user2} alt="image" />
                                              <a href="agent-profile.html">Walter White</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties3} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Single-Family Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$77,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user3} alt="image" />
                                              <a href="agent-profile.html">Jane Ronan</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties4} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Newly Built Homes</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$33,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user4} alt="image" />
                                              <a href="agent-profile.html">Jack Smith</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties5} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <a href="property-grid.html" class="featured-btn">Featured</a>
                                          </li>
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Senior Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$65,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user5} alt="image" />
                                              <a href="agent-profile.html">Jenny Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-md-6">
                              <div class="properties-item">
                                  <div class="properties-image">
                                      <a href="property-details.html">
                                          <img src={properties6} alt="image"/>
                                      </a>
                                      <ul class="action">
                                          <li>
                                              <div class="media">
                                                  <span><i class="ri-vidicon-fill"></i></span>
                                                  <span><i class="ri-image-line"></i>5</span>
                                              </div>
                                          </li>
                                      </ul>
                                      <ul class="link-list">
                                          <li>
                                              <a href="property-grid.html" class="link-btn">Apartment</a>
                                          </li>
                                          <li>
                                              <a href="property-grid.html" class="link-btn">For Sale</a>
                                          </li>
                                      </ul>
                                      <ul class="info-list">
                                          <li>
                                              <div class="icon">
                                                  <img src={bed} alt="" />
                                              </div>
                                              <span>6</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={bathroom} alt="" />
                                              </div>
                                              <span>4</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={parking} alt="" />
                                              </div>
                                              <span>1</span>
                                          </li>
                                          <li>
                                              <div class="icon">
                                                  <img src={area} alt="" />
                                              </div>
                                              <span>3250</span>
                                          </li>
                                      </ul>
                                  </div>
                                  <div class="properties-content">
                                      <div class="top">
                                          <div class="title">
                                              <h3>
                                                  <a href="property-details.html">Luxury Apartments</a>
                                              </h3>
                                              <span>194 Mercer Street, NY 10012, USA</span>
                                          </div>
                                          <div class="price">$89,000</div>
                                      </div>
                                      <div class="bottom">
                                          <div class="user">
                                              <img src={user6} alt="image" />
                                              <a href="agent-profile.html">Bella Loren</a>
                                          </div>
                                          <ul class="group-info">
                                              <li>
                                                  <div class="dropdown">
                                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <i class="ri-share-line"></i>
                                                      </button>
                                                      <ul class="dropdown-menu">
                                                          <li>
                                                              <a href="https://www.facebook.com/" target="_blank">
                                                                  <i class="ri-facebook-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://twitter.com/" target="_blank">
                                                                  <i class="ri-twitter-x-line"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://www.instagram.com/" target="_blank">
                                                                  <i class="ri-instagram-fill"></i>
                                                              </a>
                                                          </li>
                                                          <li>
                                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                                  <i class="ri-linkedin-fill"></i>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                      <i class="ri-heart-line"></i>
                                                  </button>
                                              </li>
                                              <li>
                                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                      <i class="ri-arrow-left-right-line"></i>
                                                  </button>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <div class="sell-area">
      <div class="container-fluid">
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-lg-7 col-md-12">
                  <div class="sell-image"></div>
              </div>
              <div class="col-lg-5 col-md-12">
                  <div class="sell-content">
                      <span class="sub">Unlocking Dreams, Opening Doors</span>
                      <h2>Navigating Your Home Odyssey Your Sanctuary</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare venenatis, in. Pharetra iaculis consectetur augue venenatis enim adipiscing risus sit scelerisque. Id metus viverra tellus.</p>
                      <div class="inner">
                          <h3>Sell Your Property</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare.</p>
                          <a href="property-details.html" class="sell-btn">
                             
                              <img src={area_1} alt="arrow" />
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="rent-area">
      <div class="container-fluid">
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-lg-5 col-md-12">
                  <div class="rent-content">
                      <span class="sub">Beyond Brick and Mortar</span>
                      <h2>Where Vision Meets Realty Crafting Your Perfect Home</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare venenatis, in. Pharetra iaculis consectetur augue venenatis enim adipiscing risus sit scelerisque. Id metus viverra tellus.</p>
                      <div class="inner">
                          <h3>Rent A Home</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare.</p>
                          <a href="property-details.html" class="rent-btn">
                          <img src={arrow02} alt="arrow" />
                          </a>
                      </div>
                  </div>
              </div>
              <div class="col-lg-7 col-md-12">
                  <div class="rent-image"></div>
              </div>
          </div>
      </div>
  </div>
  
  <div class="fun-facts-area pt-120 pb-95">
      <div class="container">
          <div class="row justify-content-center fun-facts-with-five-columns" data-cues="slideInUp">
              <div class="col">
                  <div class="fun-facts-card">
                      <div class="d-flex align-items-center">
                          <h3 class="counter">12</h3>
                          <h3 class="text">K</h3>
                      </div>
                      <p>Our Happy Customers</p>
                  </div>
              </div>
              <div class="col">
                  <div class="fun-facts-card">
                      <div class="d-flex align-items-center">
                          <h3 class="counter">98</h3>
                          <h3 class="text">%</h3>
                      </div>
                      <p>Clients Satisfaction Rate</p>
                  </div>
              </div>
              <div class="col">
                  <div class="fun-facts-card">
                      <div class="d-flex align-items-center">
                          <h3 class="counter">6</h3>
                          <h3 class="text">+</h3>
                      </div>
                      <p>Our Office Locations</p>
                  </div>
              </div>
              <div class="col">
                  <div class="fun-facts-card">
                      <div class="d-flex align-items-center">
                          <h3 class="counter">20</h3>
                          <h3 class="text">K</h3>
                      </div>
                      <p>Total Property Sale</p>
                  </div>
              </div>
              <div class="col">
                  <div class="fun-facts-card">
                      <div class="d-flex align-items-center">
                          <h3 class="counter">85</h3>
                          <h3 class="text">+</h3>
                      </div>
                      <p>Real Estate Agents</p>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="featured-properties-area ptb-120">
      <div class="container">
          <div class="section-title text-center" data-cues="slideInUp">
              <h2>Featured Properties</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare venenatis, in. Pharetra iaculis consectetur.</p>
          </div>
      </div>
      <div class="container-fluid">
          <div class="featured-properties-slide" data-cues="slideInUp">
              <div class="slide active bg-1">
                  <div class="properties-content">
                      <div class="info">
                          <div class="media">
                              <span><i class="ri-vidicon-fill"></i></span>
                              <span><i class="ri-image-line"></i>5</span>
                          </div>
                          <ul class="group-info">
                              <li>
                                  <div class="dropdown">
                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-share-line"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li>
                                              <a href="https://www.facebook.com/" target="_blank">
                                                  <i class="ri-facebook-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://twitter.com/" target="_blank">
                                                  <i class="ri-twitter-x-line"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://www.instagram.com/" target="_blank">
                                                  <i class="ri-instagram-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                  <i class="ri-linkedin-fill"></i>
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                      <i class="ri-heart-line"></i>
                                  </button>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                      <i class="ri-arrow-left-right-line"></i>
                                  </button>
                              </li>
                          </ul>
                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-details.html">Heritage Buildings</a>
                          </h3>
                          <span>194 Mercer Street, NY 10012, USA</span>
                      </div>
                      <ul class="info-list">
                          <li>
                              <div class="icon">
                                  <img src={bed_1} alt="arrow" />
                              </div>
                              <span>6</span>
                          </li>
                          <li>
                              <div class="icon">
                                 <img src={bathroom_1} alt="arrow" />
                              </div>
                              <span>4</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={parking_1} alt="arrow" />

                              </div>
                              <span>1</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={area} alt="arrow" />

                              </div>
                              <span>3250</span>
                          </li>
                      </ul>
                      <div class="price-and-user">
                          <div class="price">$95,000</div>
                          <div class="user">
                              <img src={user1} alt="image" />
                              <a href="agent-profile.html">Thomas Klarck</a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="slide bg-2">
                  <div class="properties-content">
                      <div class="info">
                          <div class="media">
                              <span><i class="ri-vidicon-fill"></i></span>
                              <span><i class="ri-image-line"></i>5</span>
                          </div>
                          <ul class="group-info">
                              <li>
                                  <div class="dropdown">
                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-share-line"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li>
                                              <a href="https://www.facebook.com/" target="_blank">
                                                  <i class="ri-facebook-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://twitter.com/" target="_blank">
                                                  <i class="ri-twitter-x-line"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://www.instagram.com/" target="_blank">
                                                  <i class="ri-instagram-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                  <i class="ri-linkedin-fill"></i>
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                      <i class="ri-heart-line"></i>
                                  </button>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                      <i class="ri-arrow-left-right-line"></i>
                                  </button>
                              </li>
                          </ul>
                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-details.html">Industrial Spaces</a>
                          </h3>
                          <span>194 Mercer Street, NY 10012, USA</span>
                      </div>
                      <ul class="info-list">
                          <li>
                              <div class="icon">
                              <img src={bed_1} alt="arrow" />

                              </div>
                              <span>6</span>
                          </li>
                          <li>
                              <div class="icon">
                                 <img src={bathroom_1} alt="arrow" />
                              </div>
                              <span>4</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={parking_1} alt="arrow" />
                              </div>
                              <span>1</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={area_1} alt="arrow" />
                              </div>
                              <span>3250</span>
                          </li>
                      </ul>
                      <div class="price-and-user">
                          <div class="price">$195,000</div>
                          <div class="user">
                              <img src={user2} alt="image" />
                              <a href="agent-profile.html">Walter White</a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="slide bg-3">
                  <div class="properties-content">
                      <div class="info">
                          <div class="media">
                              <span><i class="ri-vidicon-fill"></i></span>
                              <span><i class="ri-image-line"></i>5</span>
                          </div>
                          <ul class="group-info">
                              <li>
                                  <div class="dropdown">
                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-share-line"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li>
                                              <a href="https://www.facebook.com/" target="_blank">
                                                  <i class="ri-facebook-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://twitter.com/" target="_blank">
                                                  <i class="ri-twitter-x-line"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://www.instagram.com/" target="_blank">
                                                  <i class="ri-instagram-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                  <i class="ri-linkedin-fill"></i>
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                      <i class="ri-heart-line"></i>
                                  </button>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                      <i class="ri-arrow-left-right-line"></i>
                                  </button>
                              </li>
                          </ul>
                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-details.html">Newly Built Homes</a>
                          </h3>
                          <span>194 Mercer Street, NY 10012, USA</span>
                      </div>
                      <ul class="info-list">
                          <li>
                              <div class="icon">
                              <img src={bed_1} alt="arrow" />

                              </div>
                              <span>6</span>
                          </li>
                          <li>
                              <div class="icon">
                                 <img src={bathroom_1} alt="arrow" />
                              </div>
                              <span>4</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={parking_1} alt="arrow" />
                              </div>
                              <span>1</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={area_1} alt="arrow" />
                              </div>
                              <span>3250</span>
                          </li>
                      </ul>
                      <div class="price-and-user">
                          <div class="price">$295,000</div>
                          <div class="user">
                              <img src={user3} alt="image" />
                              <a href="agent-profile.html">Jack Smith</a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="slide bg-4">
                  <div class="properties-content">
                      <div class="info">
                          <div class="media">
                              <span><i class="ri-vidicon-fill"></i></span>
                              <span><i class="ri-image-line"></i>5</span>
                          </div>
                          <ul class="group-info">
                              <li>
                                  <div class="dropdown">
                                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i class="ri-share-line"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                          <li>
                                              <a href="https://www.facebook.com/" target="_blank">
                                                  <i class="ri-facebook-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://twitter.com/" target="_blank">
                                                  <i class="ri-twitter-x-line"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://www.instagram.com/" target="_blank">
                                                  <i class="ri-instagram-fill"></i>
                                              </a>
                                          </li>
                                          <li>
                                              <a href="https://bd.linkedin.com/" target="_blank">
                                                  <i class="ri-linkedin-fill"></i>
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                      <i class="ri-heart-line"></i>
                                  </button>
                              </li>
                              <li>
                                  <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                      <i class="ri-arrow-left-right-line"></i>
                                  </button>
                              </li>
                          </ul>
                      </div>
                      <div class="content">
                          <h3>
                              <a href="property-details.html">Luxury Apartments</a>
                          </h3>
                          <span>194 Mercer Street, NY 10012, USA</span>
                      </div>
                      <ul class="info-list">
                          <li>
                              <div class="icon">
                                 <img src={bed_1} alt="" />
                              </div>
                              <span>6</span>
                          </li>
                          <li>
                              <div class="icon">
                                 <img src={bathroom_1} alt="arrow" />
                              </div>
                              <span>4</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={parking_1} alt="arrow" />
                              </div>
                              <span>1</span>
                          </li>
                          <li>
                              <div class="icon">
                                  <img src={area_1} alt="arrow" />
                              </div>
                              <span>3250</span>
                          </li>
                      </ul>
                      <div class="price-and-user">
                          <div class="price">$395,000</div>
                          <div class="user">
                              <img src={user4} alt="image" />
                              <a href="agent-profile.html">Jane Ronan</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="properties-area pt-120 pb-95">
      <div class="container">
          <div class="section-title text-center" data-cues="slideInUp">
              <h2>Properties For Sale</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare venenatis, in. Pharetra iaculis consectetur.</p>
          </div>
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-xl-4 col-md-6">
                  <div class="properties-item">
                      <div class="properties-image">
                          <a href="property-details.html">
                              <img src={properties7} alt="image"/>
                          </a>
                          <ul class="action">
                              <li>
                                  <a href="property-grid.html" class="featured-btn">Featured</a>
                              </li>
                              <li>
                                  <div class="media">
                                      <span><i class="ri-vidicon-fill"></i></span>
                                      <span><i class="ri-image-line"></i>5</span>
                                  </div>
                              </li>
                          </ul>
                          <ul class="link-list">
                              <li>
                                  <a href="property-grid.html" class="link-btn">Apartment</a>
                              </li>
                              <li>
                                  <a href="property-grid.html" class="link-btn">For Sale</a>
                              </li>
                          </ul>
                          <ul class="info-list">
                              <li>
                                  <div class="icon">
                                      <img src={bed} alt="" />
                                  </div>
                                  <span>6</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={bathroom} alt="" />
                                  </div>
                                  <span>4</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={parking} alt="" />
                                  </div>
                                  <span>1</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={area} alt="" />
                                  </div>
                                  <span>3250</span>
                              </li>
                          </ul>
                      </div>
                      <div class="properties-content">
                          <div class="top">
                              <div class="title">
                                  <h3>
                                      <a href="property-details.html">Heritage Buildings</a>
                                  </h3>
                                  <span>194 Mercer Street, NY 10012, USA</span>
                              </div>
                              <div class="price">$95,000</div>
                          </div>
                          <div class="bottom">
                              <div class="user">
                                  <img src={user1} alt="image" />
                                  <a href="agent-profile.html">Thomas Klarck</a>
                              </div>
                              <ul class="group-info">
                                  <li>
                                      <div class="dropdown">
                                          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                              <i class="ri-share-line"></i>
                                          </button>
                                          <ul class="dropdown-menu">
                                              <li>
                                                  <a href="https://www.facebook.com/" target="_blank">
                                                      <i class="ri-facebook-fill"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://twitter.com/" target="_blank">
                                                      <i class="ri-twitter-x-line"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://www.instagram.com/" target="_blank">
                                                      <i class="ri-instagram-fill"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://bd.linkedin.com/" target="_blank">
                                                      <i class="ri-linkedin-fill"></i>
                                                  </a>
                                              </li>
                                          </ul>
                                      </div>
                                  </li>
                                  <li>
                                      <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                          <i class="ri-heart-line"></i>
                                      </button>
                                  </li>
                                  <li>
                                      <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                          <i class="ri-arrow-left-right-line"></i>
                                      </button>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-md-6">
                  <div class="properties-item">
                      <div class="properties-image">
                          <a href="property-details.html">
                              <img src={properties7} alt="image"/>
                          </a>
                          <ul class="action">
                              <li>
                                  <div class="media">
                                      <span><i class="ri-vidicon-fill"></i></span>
                                      <span><i class="ri-image-line"></i>5</span>
                                  </div>
                              </li>
                          </ul>
                          <ul class="link-list">
                              <li>
                                  <a href="property-grid.html" class="link-btn">Apartment</a>
                              </li>
                              <li>
                                  <a href="property-grid.html" class="link-btn">For Sale</a>
                              </li>
                          </ul>
                          <ul class="info-list">
                              <li>
                                  <div class="icon">
                                      <img src={bed} alt="" />
                                  </div>
                                  <span>6</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={bathroom} alt="" />
                                  </div>
                                  <span>4</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={parking} alt="" />
                                  </div>
                                  <span>1</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={area} alt="" />
                                  </div>
                                  <span>3250</span>
                              </li>
                          </ul>
                      </div>
                      <div class="properties-content">
                          <div class="top">
                              <div class="title">
                                  <h3>
                                      <a href="property-details.html">Beachfront Properties</a>
                                  </h3>
                                  <span>194 Mercer Street, NY 10012, USA</span>
                              </div>
                              <div class="price">$55,000</div>
                          </div>
                          <div class="bottom">
                              <div class="user">
                                  <img src={user2} alt="image" />
                                  <a href="agent-profile.html">Walter White</a>
                              </div>
                              <ul class="group-info">
                                  <li>
                                      <div class="dropdown">
                                          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                              <i class="ri-share-line"></i>
                                          </button>
                                          <ul class="dropdown-menu">
                                              <li>
                                                  <a href="https://www.facebook.com/" target="_blank">
                                                      <i class="ri-facebook-fill"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://twitter.com/" target="_blank">
                                                      <i class="ri-twitter-x-line"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://www.instagram.com/" target="_blank">
                                                      <i class="ri-instagram-fill"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://bd.linkedin.com/" target="_blank">
                                                      <i class="ri-linkedin-fill"></i>
                                                  </a>
                                              </li>
                                          </ul>
                                      </div>
                                  </li>
                                  <li>
                                      <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                          <i class="ri-heart-line"></i>
                                      </button>
                                  </li>
                                  <li>
                                      <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                          <i class="ri-arrow-left-right-line"></i>
                                      </button>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-md-6">
                  <div class="properties-item">
                      <div class="properties-image">
                          <a href="property-details.html">
                              <img src={properties7} alt="image"/>
                          </a>
                          <ul class="action">
                              <li>
                                  <a href="property-grid.html" class="featured-btn">Featured</a>
                              </li>
                              <li>
                                  <div class="media">
                                      <span><i class="ri-vidicon-fill"></i></span>
                                      <span><i class="ri-image-line"></i>5</span>
                                  </div>
                              </li>
                          </ul>
                          <ul class="link-list">
                              <li>
                                  <a href="property-grid.html" class="link-btn">Apartment</a>
                              </li>
                              <li>
                                  <a href="property-grid.html" class="link-btn">For Sale</a>
                              </li>
                          </ul>
                          <ul class="info-list">
                              <li>
                                  <div class="icon">
                                      <img src={bed} alt="" />
                                  </div>
                                  <span>6</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={bathroom} alt="" />
                                  </div>
                                  <span>4</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={parking} alt="" />
                                  </div>
                                  <span>1</span>
                              </li>
                              <li>
                                  <div class="icon">
                                      <img src={area} alt="" />
                                  </div>
                                  <span>3250</span>
                              </li>
                          </ul>
                      </div>
                      <div class="properties-content">
                          <div class="top">
                              <div class="title">
                                  <h3>
                                      <a href="property-details.html">Luxury Apartments</a>
                                  </h3>
                                  <span>194 Mercer Street, NY 10012, USA</span>
                              </div>
                              <div class="price">$77,000</div>
                          </div>
                          <div class="bottom">
                              <div class="user">
                                  <img src={user3} alt="image" />
                                  <a href="agent-profile.html">Jane Ronan</a>
                              </div>
                              <ul class="group-info">
                                  <li>
                                      <div class="dropdown">
                                          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                              <i class="ri-share-line"></i>
                                          </button>
                                          <ul class="dropdown-menu">
                                              <li>
                                                  <a href="https://www.facebook.com/" target="_blank">
                                                      <i class="ri-facebook-fill"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://twitter.com/" target="_blank">
                                                      <i class="ri-twitter-x-line"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://www.instagram.com/" target="_blank">
                                                      <i class="ri-instagram-fill"></i>
                                                  </a>
                                              </li>
                                              <li>
                                                  <a href="https://bd.linkedin.com/" target="_blank">
                                                      <i class="ri-linkedin-fill"></i>
                                                  </a>
                                              </li>
                                          </ul>
                                      </div>
                                  </li>
                                  <li>
                                      <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                          <i class="ri-heart-line"></i>
                                      </button>
                                  </li>
                                  <li>
                                      <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                          <i class="ri-arrow-left-right-line"></i>
                                      </button>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="information-area">
      <div class="container">
          <div class="information-inner-area">
              <div class="row justify-content-center align-items-center" data-cues="slideInUp">
                  <div class="col-xl-6 col-md-12">
                      <div class="information-content">
                          <span class="sub">
                              Exploring Unique Homes in the Real Estate Market
                          </span>
                          <h2>Looking To Buy A Property?</h2>
                      </div>
                  </div>
                  <div class="col-xl-6 col-md-12">
                      <ul class="information-list">
                          <li>
                              <div class="phone-info">
                                  <div class="icon">
                                      <i class="ri-phone-line"></i>
                                  </div>
                                  <a href="tel:+91 9694949481">+91 9694949481</a>
                              </div>
                          </li>
                          <li>
                              <a href="property-grid.html" class="default-btn">Find Premium Property</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="agents-area pt-120 pb-95">
      <div class="container">
          <div class="section-title text-center" data-cues="slideInUp">
              <h2>Real Estate Agents</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare venenatis, in. Pharetra iaculis consectetur.</p>
          </div>
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-xl-3 col-md-6">
                  <div class="agents-card">
                      <div class="agents-image">
                          <a href="agent-profile.html">
                           
                              <img src={agents1} alt="" />
                          </a>
                      </div>
                      <div class="agents-content">
                          <h3>
                              <a href="agent-profile.html">Christopher Baker</a>
                          </h3>
                          <span>Residential Property Manager</span>
                          <div class="social-info">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-md-6">
                  <div class="agents-card">
                      <div class="agents-image">
                          <a href="agent-profile.html">
                          <img src={agents2} alt="" />
                          </a>
                      </div>
                      <div class="agents-content">
                          <h3>
                              <a href="agent-profile.html">Ryan Anderson</a>
                          </h3>
                          <span>Residential Appraiser</span>
                          <div class="social-info">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-md-6">
                  <div class="agents-card">
                      <div class="agents-image">
                          <a href="agent-profile.html">
                          <img src={agents3} alt="" />
                          </a>
                      </div>
                      <div class="agents-content">
                          <h3>
                              <a href="agent-profile.html">Ashley Martin</a>
                          </h3>
                          <span>Commercial Consultant</span>
                          <div class="social-info">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-md-6">
                  <div class="agents-card">
                      <div class="agents-image">
                          <a href="agent-profile.html">
                          <img src={agents4} alt="" />
                          </a>
                      </div>
                      <div class="agents-content">
                          <h3>
                              <a href="agent-profile.html">Brandon Mitchell</a>
                          </h3>
                          <span>Construction Superintendent</span>
                          <div class="social-info">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  {/* <div class="testimonial-area pb-120">
      <div class="container-fluid" data-cues="slideInUp">
          <div class="swiper testimonial-slider">
              <div class="swiper-wrapper">
                  <div class="swiper-slide">
                      <div class="testimonial-card">
                          <div class="info">
                              <div class="image">
                                  <img src={user1} alt="image" />
                              </div>
                              <div class="title">
                                  <h3>Jordan Williams</h3>
                                  <span>Commercial Property Manager</span>
                              </div>
                          </div>
                          <p>“I highly recommend Dreamkothi agent to anyone looking to buy or sell a home. They are professional, reliable, and truly care about their clients' needs. They are professional, reliable, and truly care about their clients' needs.”</p>
                          <ul class="rating">
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li class="gray"><i class="ri-star-fill"></i></li>
                          </ul>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="testimonial-card">
                          <div class="info">
                              <div class="image">
                                  <img src={user2} alt="image" />
                              </div>
                              <div class="title">
                                  <h3>Brandon Mitchell</h3>
                                  <span>Project Developer</span>
                              </div>
                          </div>
                          <p>“I highly recommend Dreamkothi agent to anyone looking to buy or sell a home. They are professional, reliable, and truly care about their clients' needs. They are professional, reliable, and truly care about their clients' needs.”</p>
                          <ul class="rating">
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                          </ul>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="testimonial-card">
                          <div class="info">
                              <div class="image">
                                  <img src={user3} alt="image" />
                              </div>
                              <div class="title">
                                  <h3>Brandon Mitchell</h3>
                                  <span>Residential Appraiser</span>
                              </div>
                          </div>
                          <p>“I highly recommend Dreamkothi agent to anyone looking to buy or sell a home. They are professional, reliable, and truly care about their clients' needs. They are professional, reliable, and truly care about their clients' needs.”</p>
                          <ul class="rating">
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li class="gray"><i class="ri-star-fill"></i></li>
                          </ul>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="testimonial-card">
                          <div class="info">
                              <div class="image">
                                  <img src={user1} alt="image" />
                              </div>
                              <div class="title">
                                  <h3>Jordan Williams</h3>
                                  <span>Commercial Property Manager</span>
                              </div>
                          </div>
                          <p>“I highly recommend Dreamkothi agent to anyone looking to buy or sell a home. They are professional, reliable, and truly care about their clients' needs. They are professional, reliable, and truly care about their clients' needs.”</p>
                          <ul class="rating">
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li class="gray"><i class="ri-star-fill"></i></li>
                          </ul>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="testimonial-card">
                          <div class="info">
                              <div class="image">
                                  <img src={user2} alt="image" />
                              </div>
                              <div class="title">
                                  <h3>Brandon Mitchell</h3>
                                  <span>Project Developer</span>
                              </div>
                          </div>
                          <p>“I highly recommend Dreamkothi agent to anyone looking to buy or sell a home. They are professional, reliable, and truly care about their clients' needs. They are professional, reliable, and truly care about their clients' needs.”</p>
                          <ul class="rating">
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                          </ul>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="testimonial-card">
                          <div class="info">
                              <div class="image">
                                  <img src={user3} alt="image" />
                              </div>
                              <div class="title">
                                  <h3>Brandon Mitchell</h3>
                                  <span>Residential Appraiser</span>
                              </div>
                          </div>
                          <p>“I highly recommend Dreamkothi agent to anyone looking to buy or sell a home. They are professional, reliable, and truly care about their clients' needs. They are professional, reliable, and truly care about their clients' needs.”</p>
                          <ul class="rating">
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li><i class="ri-star-fill"></i></li>
                              <li class="gray"><i class="ri-star-fill"></i></li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="testimonial-pagination"></div>
          </div>
      </div>
  </div> */}

  <div class="blog-area pb-95">
      <div class="container">
          <div class="section-title text-center" data-cues="slideInUp">
              <h2>Latest Articles</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et mauris eget ornare venenatis, in. Pharetra iaculis consectetur.</p>
          </div>
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-xl-4 col-md-6">
                  <div class="blog-card">
                      <div class="blog-image">
                          <a href="blog-details.html">
                            
                              <img src={blog1} alt="" />
                          </a>
                          <a href="blog-grid.html" class="tag-btn">Real Estate</a>
                          <a href="author.html" class="author-btn">
                              <img src={user1} alt="image" />
                          </a>
                      </div>
                      <div class="blog-content">
                          <ul class="meta">
                              <li>
                                  <i class="ri-calendar-2-line"></i>
                                  December 21, 2024
                              </li>
                              <li>
                                  <i class="ri-message-2-line"></i>
                                  05
                              </li>
                          </ul>
                          <h3>
                              <a href="blog-details.html">Unveiling the Digital Landscape of Real Estate</a>
                          </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-md-6">
                  <div class="blog-card">
                      <div class="blog-image">
                          <a href="blog-details.html">
                          <img src={blog2} alt="" />
                          </a>
                          <a href="blog-grid.html" class="tag-btn">Building</a>
                          <a href="author.html" class="author-btn">
                              <img src={user2} alt="image" />
                          </a>
                      </div>
                      <div class="blog-content">
                          <ul class="meta">
                              <li>
                                  <i class="ri-calendar-2-line"></i>
                                  December 22, 2024
                              </li>
                              <li>
                                  <i class="ri-message-2-line"></i>
                                  10
                              </li>
                          </ul>
                          <h3>
                              <a href="blog-details.html">Spaces that Speak, Homes that Hear Your Real Estate Connection</a>
                          </h3>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-md-6">
                  <div class="blog-card">
                      <div class="blog-image">
                          <a href="blog-details.html">
                          <img src={blog3} alt="" />
                          </a>
                          <a href="blog-grid.html" class="tag-btn">Architecture</a>
                          <a href="author.html" class="author-btn">
                              <img src={user3} alt="image" />
                          </a>
                      </div>
                      <div class="blog-content">
                          <ul class="meta">
                              <li>
                                  <i class="ri-calendar-2-line"></i>
                                  December 25, 2024
                              </li>
                              <li>
                                  <i class="ri-message-2-line"></i>
                                  12
                              </li>
                          </ul>
                          <h3>
                              <a href="blog-details.html">Stories and Insights from the Real Estate Frontline</a>
                          </h3>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <footer class="footer-area pt-120">
      <div class="container">
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-xl-3 col-md-12">
                  <div class="single-footer-widget pe-3">
                      <div class="widget-logo">
                          <a href="index.html">
                               {/* <img src="images/logo2.png" alt="logo2"> */}
                              <b class="text-white">DREAMKOTHI</b>
                          </a>
                      </div>
                      <p>Lorem ipsum dolor sit amet, elit dollar consectetur adipiscing elit. Diam lectus purus ultricies neque.</p>
                      <div class="widget-social">
                          <a href="https://www.facebook.com/" target="_blank">
                              <i class="ri-facebook-fill"></i>
                          </a>
                          <a href="https://twitter.com/" target="_blank">
                              <i class="ri-twitter-x-line"></i>
                          </a>
                          <a href="https://www.instagram.com/" target="_blank">
                              <i class="ri-instagram-fill"></i>
                          </a>
                          <a href="https://bd.linkedin.com/" target="_blank">
                              <i class="ri-linkedin-fill"></i>
                          </a>
                          <a href="https://www.youtube.com/" target="_blank">
                              <i class="ri-youtube-line"></i>
                          </a>
                          <a href="https://www.pinterest.com/" target="_blank">
                              <i class="ri-pinterest-line"></i>
                          </a>
                      </div>
                  </div>
              </div>
              <div class="col-xl-9 col-md-12">
                  <div class="row justify-content-center" data-cues="slideInUp">
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Company</h3>
                              <ul class="custom-links">
                                  <li><a href="about-us.html">About Us</a></li>
                                  <li><a href="contact-us.html">Contact Us</a></li>
                                  <li><a href="customers-review.html">Our Reviews</a></li>
                                  <li><a href="terms-conditions.html">Terms &amp; Conditions</a></li>
                                  <li><a href="privacy-policy.html">Privacy Policy</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Resources</h3>
                              <ul class="custom-links">
                                  <li><a href="property-grid.html">Apartments</a></li>
                                  <li><a href="property-grid.html">Villa</a></li>
                                  <li><a href="property-grid.html">Sell or Buy</a></li>
                                  <li><a href="property-grid.html">New Apartment</a></li>
                                  <li><a href="agents.html">Our Agents</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Quick Links</h3>
                              <ul class="custom-links">
                                  <li><a href="pricing.html">Pricing</a></li>
                                  <li><a href="what-we-do.html">What We Do</a></li>
                                  <li><a href="customers-review.html">Testimonial</a></li>
                                  <li><a href="blog-grid.html">Blog</a></li>
                                  <li><a href="neighborhood.html">Neighborhood</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget">
                              <h3>Contact Info</h3>
                              <ul class="info-list">
                                  <li>
                                      <span>Address: </span>
                                      <a href="https://maps.app.goo.gl/JHGxiX1hVZQEDi1B6">
                                      SHOP NO S-45 JANAKPURI COLONY NEAR HATHOJ STAND,JHOTWARA, JAIPUR, RAJASTHAN - 302012
                                      </a>
                                       
                                  </li>
                                  <li>
                                      <span>Email: </span> 
                                      <a href="mailto:dreamkothi@gmail.com">dreamkothi@gmail.com</a>
                                  </li>
                                  <li>
                                      <span>Phone:</span>
                                      <a href="tel:+91 9694949481">+91 9694949481</a>
                                  </li>
                                 
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="copyright-area">
              <p>
                  Copyright <span>Dreamkothi</span> <a href="https://envytheme.com/" target="_blank"></a>
              </p>
          </div>
      </div>
  </footer>
  
  <button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
      <i class="ri-arrow-up-s-line"></i>
  </button>

  <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
  </div>



</section>
)



}

export default HomeScreen;