import React from "react";
 const Login = () =>{

    return(
        <>
              <div class="top-header-area">
      <div class="container-fluid">
          <div class="row justify-content-center align-items-center">
              <div class="col-lg-7 col-md-7">
                  <ul class="top-header-info-with-social">
                      <li>
                          <div class="social">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </li>
                      <li>
                          <div class="mail-info">
                              <i class="ri-mail-line"></i>
                              <a href="mailto:dreamkothi@gmail.com"><span class="__cf_email__">dreamkothi@gmail.com</span></a>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-5 col-md-5 text-end">
                  <div class="top-header-call-info">
                      <i class="ri-phone-line"></i>
                      <a href="tel:+91 9694949481">+91 9694949481</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <nav class="navbar navbar-expand-xl" id="navbar">
      <div class="container-fluid">
          <a class="navbar-brand" href="index.html">
               {/* <img src="images/logo.png" alt="logo">  */}

              <b>DREAMKOTHI</b>
          </a>
          <form class="search-form">
              <input type="search" class="search-field" placeholder="Search property"/>
              <button type="submit">
                  <i class="ri-search-line"></i>
              </button>
          </form>
          <button class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas">
              <span class="burger-menu">
                  <span class="top-bar"></span>
                  <span class="middle-bar"></span>
                  <span class="bottom-bar"></span>
              </span>
          </button>
          <div class="collapse navbar-collapse">
              <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link active">
                          Home
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      {/* <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a href="index.html" class="nav-link active">HOME</a>
                          </li>
                        
                      </ul> */}
                  </li>
                  {/* <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                          Pages
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a href="about-us.html" class="nav-link">About Us</a>
                          </li>
                          <li class="nav-item">
                              <a href="agents.html" class="nav-link">Agents</a>
                          </li>
                          <li class="nav-item">
                              <a href="agent-profile.html" class="nav-link">Agent Profile</a>
                          </li>
                          <li class="nav-item">
                              <a href="pricing.html" class="nav-link">Pricing</a>
                          </li>
                          <li class="nav-item">
                              <a href="what-we-do.html" class="nav-link">What We Do</a>
                          </li>
                          <li class="nav-item">
                              <a href="neighborhood.html" class="nav-link">Neighborhood</a>
                          </li>
                          <li class="nav-item">
                              <a href="inquiry-form.html" class="nav-link">Inquiry Form</a>
                          </li>
                          <li class="nav-item">
                              <a href="gallery.html" class="nav-link">Gallery</a>
                          </li>
                          <li class="nav-item">
                              <a href="customers-review.html" class="nav-link">Customers Review</a>
                          </li>
                          <li class="nav-item">
                              <a href="faq.html" class="nav-link">FAQ</a>
                          </li>
                          <li class="nav-item">
                              <a href="my-account.html" class="nav-link">My Account</a>
                          </li>
                          <li class="nav-item">
                              <a href="privacy-policy.html" class="nav-link">Privacy Policy</a>
                          </li>
                          <li class="nav-item">
                              <a href="terms-conditions.html" class="nav-link">Terms &amp; Conditions</a>
                          </li>
                          <li class="nav-item">
                              <a href="not-found.html" class="nav-link">404 Error Page</a>
                          </li>
                      </ul> 
                  </li> */}
                  <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                          Property
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          
                        
                          
                          <li class="nav-item">
                              <a href="property-with-map.html" class="nav-link">Property Details With Map</a>
                          </li>
                        
                         
                      </ul>
                  </li>
                 
                  <li class="nav-item">
                      <a href="contact-us.html" class="nav-link">Contact Us</a>
                  </li>
              </ul>
              <div class="others-option d-flex align-items-center">
                  <div class="option-item">
                      <div class="user-info">
                          <a href="my-account.html">Log In / Register</a>
                      </div>
                  </div>
                  <div class="option-item">
                      <a href="contact-us.html" class="default-btn">Contact Us</a>
                  </div>
              </div>
          </div>
      </div>
  </nav>

  <div class="responsive-navbar offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvas">
      <div class="offcanvas-header">
          <a href="index.html" class="logo d-inline-block">
              {/* <img src="images/logo.png" alt="logo"> */}
          </a>
          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" class="close-btn">
              <i class="ri-close-line"></i>
          </button>
      </div>
      <div class="offcanvas-body">
          <div class="accordion" id="navbarAccordion">
              <div class="accordion-item">
                  <button class="accordion-button collapsed active" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Home
                  </button>
                  <div id="collapseOne" class="accordion-collapse collapse " data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a class="accordion-link active" href="index.html">
                                       Demo
                                  </a>
                              </div>
                           
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Property
                  </button>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a href="property-grid.html" class="accordion-link">
                                      Property All
                                  </a>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <a class="accordion-button without-icon" href="contact-us.html">
                      Contact Us
                  </a>
              </div>
          </div>
          <div class="others-option">
              <div class="option-item">
                  <div class="user-info">
                      <a href="my-account.html">Log In / Register</a>
                  </div>
              </div>
              <div class="option-item">
                  <a href="contact-us.html" class="default-btn">Contact Us</a>
              </div>
              <div class="option-item">
                  <form class="search-form">
                      <input type="search" class="search-field" placeholder="Search property"/>
                      <button type="submit">
                          <i class="ri-search-line"></i>
                      </button>
                  </form>
              </div>
          </div>
      </div>
  </div>
            <div class="page-banner-area">
        <div class="container">
            <div class="page-banner-content">
                <h2>Login / Register</h2>
                <ul class="list">
                    <li>
                        <a href="index.html">Home</a>
                    </li>
                    <li>Account</li>
                </ul>
            </div>
        </div>
    </div>
    
    <div class="profile-authentication-area ptb-120">
        <div class="container">
            <div class="profile-authentication-inner">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="profile-authentication-box">
                            <div class="content">
                                <h3>Sign In</h3>
                                <p>Don't have an account yet? <a href="my-account.html">Sign up here</a></p>
                            </div>
                            <form class="authentication-form">
                                <div class="google-btn">
                                    <button type="submit">
                                        {/* <img src="../../templates.envytheme.com/andora/default/assets/images/google.svg" alt="google"> */}
                                            Sign in with Google</button>
                                </div>
                                <div class="or">
                                    <span>OR</span>
                                </div>
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" placeholder="Enter email address"/>
                                    <div class="icon">
                                        <i class="ri-mail-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Your Password</label>
                                    <input type="password" class="form-control" placeholder="Your password"/>
                                    <div class="icon">
                                        <i class="ri-lock-line"></i>
                                    </div>
                                </div>
                                <div class="form-bottom d-flex justify-content-between">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="remember-me"/>
                                        <label class="form-check-label" for="remember-me">
                                            Remember me
                                        </label>
                                    </div>
                                    <a href="forgot-password.html" class="forgot-password">
                                        Forgot your password?
                                    </a>
                                </div>
                                <button type="submit" class="default-btn">Sign In</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="profile-authentication-box with-gap">
                            <div class="content">
                                <h3>Create Your Account</h3>
                                <p>Already have an account? <a href="my-account.html">Sign in here</a></p>
                            </div>
                            <form class="authentication-form">
                                <div class="google-btn">
                                    <button type="submit">
                                        {/* <img src="../../templates.envytheme.com/andora/default/assets/images/google.svg" alt="google"> */}
                                            Sign in with Google</button>
                                </div>
                                <div class="or">
                                    <span>OR</span>
                                </div>
                                <div class="form-group">
                                    <label>Your Name</label>
                                    <input type="text" class="form-control" placeholder="Enter name"/>
                                    <div class="icon">
                                        <i class="ri-user-3-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" placeholder="Enter email address"/>
                                    <div class="icon">
                                        <i class="ri-mail-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Your Password</label>
                                    <input type="password" class="form-control" placeholder="Your password"/>
                                    <div class="icon">
                                        <i class="ri-lock-line"></i>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Confirm Password</label>
                                    <input type="password" class="form-control" placeholder="Confirm password"/>
                                    <div class="icon">
                                        <i class="ri-lock-line"></i>
                                    </div>
                                </div>
                                <div class="form-bottom">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="checkbox1"/>
                                        <label class="form-check-label" for="checkbox1">
                                            I accept the <a href="terms-conditions.html">Terms and Conditions</a>
                                        </label>
                                    </div>
                                </div>
                                <button type="submit" class="default-btn">Sign Up</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="subscribe-wrap-area">
        <div class="container" data-cues="slideInUp">
            <div class="subscribe-wrap-inner-area">
                <div class="subscribe-content">
                    <h2>Subscribe To Our Newsletter</h2>
                    <form class="subscribe-form">
                        <input type="search" class="form-control" placeholder="Enter your email"/>
                        <button type="submit" class="default-btn">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer-area pt-120">
      <div class="container">
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-xl-3 col-md-12">
                  <div class="single-footer-widget pe-3">
                      <div class="widget-logo">
                          <a href="index.html">
                               {/* <img src="images/logo2.png" alt="logo2"> */}
                              <b class="text-white">DREAMKOTHI</b>
                          </a>
                      </div>
                      <p>Lorem ipsum dolor sit amet, elit dollar consectetur adipiscing elit. Diam lectus purus ultricies neque.</p>
                      <div class="widget-social">
                          <a href="https://www.facebook.com/" target="_blank">
                              <i class="ri-facebook-fill"></i>
                          </a>
                          <a href="https://twitter.com/" target="_blank">
                              <i class="ri-twitter-x-line"></i>
                          </a>
                          <a href="https://www.instagram.com/" target="_blank">
                              <i class="ri-instagram-fill"></i>
                          </a>
                          <a href="https://bd.linkedin.com/" target="_blank">
                              <i class="ri-linkedin-fill"></i>
                          </a>
                          <a href="https://www.youtube.com/" target="_blank">
                              <i class="ri-youtube-line"></i>
                          </a>
                          <a href="https://www.pinterest.com/" target="_blank">
                              <i class="ri-pinterest-line"></i>
                          </a>
                      </div>
                  </div>
              </div>
              <div class="col-xl-9 col-md-12">
                  <div class="row justify-content-center" data-cues="slideInUp">
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Company</h3>
                              <ul class="custom-links">
                                  <li><a href="about-us.html">About Us</a></li>
                                  <li><a href="contact-us.html">Contact Us</a></li>
                                  <li><a href="customers-review.html">Our Reviews</a></li>
                                  <li><a href="terms-conditions.html">Terms &amp; Conditions</a></li>
                                  <li><a href="privacy-policy.html">Privacy Policy</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Resources</h3>
                              <ul class="custom-links">
                                  <li><a href="property-grid.html">Apartments</a></li>
                                  <li><a href="property-grid.html">Villa</a></li>
                                  <li><a href="property-grid.html">Sell or Buy</a></li>
                                  <li><a href="property-grid.html">New Apartment</a></li>
                                  <li><a href="agents.html">Our Agents</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Quick Links</h3>
                              <ul class="custom-links">
                                  <li><a href="pricing.html">Pricing</a></li>
                                  <li><a href="what-we-do.html">What We Do</a></li>
                                  <li><a href="customers-review.html">Testimonial</a></li>
                                  <li><a href="blog-grid.html">Blog</a></li>
                                  <li><a href="neighborhood.html">Neighborhood</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget">
                              <h3>Contact Info</h3>
                              <ul class="info-list">
                                  <li>
                                      <span>Address: </span>
                                      <a href="https://maps.app.goo.gl/JHGxiX1hVZQEDi1B6">
                                      SHOP NO S-45 JANAKPURI COLONY NEAR HATHOJ STAND,JHOTWARA, JAIPUR, RAJASTHAN - 302012
                                      </a>
                                       
                                  </li>
                                  <li>
                                      <span>Email: </span> 
                                      <a href="mailto:dreamkothi@gmail.com">dreamkothi@gmail.com</a>
                                  </li>
                                  <li>
                                      <span>Phone:</span>
                                      <a href="tel:+91 9694949481">+91 9694949481</a>
                                  </li>
                                 
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="copyright-area">
              <p>
                  Copyright <span>Dreamkothi</span> <a href="https://envytheme.com/" target="_blank"></a>
              </p>
          </div>
      </div>
  </footer>
    <button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
        <i class="ri-arrow-up-s-line"></i>
    </button>
        </>

)



}

export default Login;