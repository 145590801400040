import React from "react";
import properties11 from '../../images/properties11.jpg'
import bed from '../../images/bed.svg'
import bathroom from '../../images/bathroom.svg'
import parking from '../../images/parking.svg'
import area from '../../images/area.svg'
import bed2 from '../../images/bed2.svg'
import bathroom2 from '../../images/bathroom2.svg'
import parking2 from '../../images/parking2.svg'
import area2 from '../../images/area2.svg'
import user2 from '../../images/user2.png'


const AllProperty = () =>{

return(

    <mydiv>


    
    {/* <div class="preloader-area text-center position-fixed top-0 bottom-0 start-0 end-0" id="preloader">
    <div class="loader position-absolute start-0 end-0">
        <img src="images/favicon.png" alt="favicon"/>
        <div class="waviy position-relative">
            <span class="d-inline-block">D</span>
            <span class="d-inline-block">R</span>
            <span class="d-inline-block">E</span>
            <span class="d-inline-block">M</span>
            <span class="d-inline-block">K</span>
            <span class="d-inline-block">O</span>
            <span class="d-inline-block">T</span>
            <span class="d-inline-block">H</span>
            <span class="d-inline-block">I</span>

        </div>
    </div>
</div> */}
  <div class="top-header-area">
      <div class="container-fluid">
          <div class="row justify-content-center align-items-center">
              <div class="col-lg-7 col-md-7">
                  <ul class="top-header-info-with-social">
                      <li>
                          <div class="social">
                              <a href="https://www.facebook.com/" target="_blank">
                                  <i class="ri-facebook-fill"></i>
                              </a>
                              <a href="https://twitter.com/" target="_blank">
                                  <i class="ri-twitter-x-line"></i>
                              </a>
                              <a href="https://www.instagram.com/" target="_blank">
                                  <i class="ri-instagram-fill"></i>
                              </a>
                              <a href="https://bd.linkedin.com/" target="_blank">
                                  <i class="ri-linkedin-fill"></i>
                              </a>
                              <a href="https://www.youtube.com/" target="_blank">
                                  <i class="ri-youtube-line"></i>
                              </a>
                              <a href="https://www.pinterest.com/" target="_blank">
                                  <i class="ri-pinterest-line"></i>
                              </a>
                          </div>
                      </li>
                      <li>
                          <div class="mail-info">
                              <i class="ri-mail-line"></i>
                              <a href="mailto:dreamkothi@gmail.com"><span class="__cf_email__">dreamkothi@gmail.com</span></a>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="col-lg-5 col-md-5 text-end">
                  <div class="top-header-call-info">
                      <i class="ri-phone-line"></i>
                      <a href="tel:+91 9694949481">+91 9694949481</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <nav class="navbar navbar-expand-xl" id="navbar">
      <div class="container-fluid">
          <a class="navbar-brand" href="index.html">
               {/* <img src="images/logo.png" alt="logo">  */}

              <b>DREAMKOTHI</b>
          </a>
          <form class="search-form">
              <input type="search" class="search-field" placeholder="Search property"/>
              <button type="submit">
                  <i class="ri-search-line"></i>
              </button>
          </form>
          <button class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvas">
              <span class="burger-menu">
                  <span class="top-bar"></span>
                  <span class="middle-bar"></span>
                  <span class="bottom-bar"></span>
              </span>
          </button>
          <div class="collapse navbar-collapse">
              <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link active">
                          Home
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      {/* <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a href="index.html" class="nav-link active">HOME</a>
                          </li>
                        
                      </ul> */}
                  </li>
                  {/* <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                          Pages
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a href="about-us.html" class="nav-link">About Us</a>
                          </li>
                          <li class="nav-item">
                              <a href="agents.html" class="nav-link">Agents</a>
                          </li>
                          <li class="nav-item">
                              <a href="agent-profile.html" class="nav-link">Agent Profile</a>
                          </li>
                          <li class="nav-item">
                              <a href="pricing.html" class="nav-link">Pricing</a>
                          </li>
                          <li class="nav-item">
                              <a href="what-we-do.html" class="nav-link">What We Do</a>
                          </li>
                          <li class="nav-item">
                              <a href="neighborhood.html" class="nav-link">Neighborhood</a>
                          </li>
                          <li class="nav-item">
                              <a href="inquiry-form.html" class="nav-link">Inquiry Form</a>
                          </li>
                          <li class="nav-item">
                              <a href="gallery.html" class="nav-link">Gallery</a>
                          </li>
                          <li class="nav-item">
                              <a href="customers-review.html" class="nav-link">Customers Review</a>
                          </li>
                          <li class="nav-item">
                              <a href="faq.html" class="nav-link">FAQ</a>
                          </li>
                          <li class="nav-item">
                              <a href="my-account.html" class="nav-link">My Account</a>
                          </li>
                          <li class="nav-item">
                              <a href="privacy-policy.html" class="nav-link">Privacy Policy</a>
                          </li>
                          <li class="nav-item">
                              <a href="terms-conditions.html" class="nav-link">Terms &amp; Conditions</a>
                          </li>
                          <li class="nav-item">
                              <a href="not-found.html" class="nav-link">404 Error Page</a>
                          </li>
                      </ul> 
                  </li> */}
                  <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                          Property
                          <i class="ri-arrow-down-s-line"></i>
                      </a>
                      <ul class="dropdown-menu">
                          
                        
                          
                          <li class="nav-item">
                              <a href="property-with-map.html" class="nav-link">Property Details With Map</a>
                          </li>
                        
                         
                      </ul>
                  </li>
                 
                  <li class="nav-item">
                      <a href="contact-us.html" class="nav-link">Contact Us</a>
                  </li>
              </ul>
              <div class="others-option d-flex align-items-center">
                  <div class="option-item">
                      <div class="user-info">
                          <a href="my-account.html">Log In / Register</a>
                      </div>
                  </div>
                  <div class="option-item">
                      <a href="contact-us.html" class="default-btn">Contact Us</a>
                  </div>
              </div>
          </div>
      </div>
  </nav>

  <div class="responsive-navbar offcanvas offcanvas-end" tabindex="-1" id="navbarOffcanvas">
      <div class="offcanvas-header">
          <a href="index.html" class="logo d-inline-block">
              {/* <img src="images/logo.png" alt="logo"> */}
          </a>
          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" class="close-btn">
              <i class="ri-close-line"></i>
          </button>
      </div>
      <div class="offcanvas-body">
          <div class="accordion" id="navbarAccordion">
              <div class="accordion-item">
                  <button class="accordion-button collapsed active" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Home
                  </button>
                  <div id="collapseOne" class="accordion-collapse collapse " data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a class="accordion-link active" href="index.html">
                                       Demo
                                  </a>
                              </div>
                           
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Property
                  </button>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                      <div class="accordion-body">
                          <div class="accordion" id="navbarAccordion">
                              <div class="accordion-item">
                                  <a href="property-grid.html" class="accordion-link">
                                      Property All
                                  </a>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
             
              <div class="accordion-item">
                  <a class="accordion-button without-icon" href="contact-us.html">
                      Contact Us
                  </a>
              </div>
          </div>
          <div class="others-option">
              <div class="option-item">
                  <div class="user-info">
                      <a href="my-account.html">Log In / Register</a>
                  </div>
              </div>
              <div class="option-item">
                  <a href="contact-us.html" class="default-btn">Contact Us</a>
              </div>
              <div class="option-item">
                  <form class="search-form">
                      <input type="search" class="search-field" placeholder="Search property"/>
                      <button type="submit">
                          <i class="ri-search-line"></i>
                      </button>
                  </form>
              </div>
          </div>
      </div>
  </div>
    
    <div class="page-banner-area">
        <div class="container">
            <div class="page-banner-content">
                <h2>Property Top Filter</h2>
                <ul class="list">
                    <li>
                        <a href="index.html">Home</a>
                    </li>
                    <li>Property Top Filter</li>
                </ul>
            </div>
        </div>
    </div>
    
    <div class="properties-wrap-area without-wrap-bg pt-120 pb-120">
        <div class="container">
            <div class="properties-search-info-tabs">
                <ul class="nav nav-tabs" id="search_tab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="sell-tab" data-bs-toggle="tab" href="#sell" role="tab" aria-controls="sell">Sell</a></li>
                    <li class="nav-item"><a class="nav-link" id="rent-tab" data-bs-toggle="tab" href="#rent" role="tab" aria-controls="rent">Rent</a></li>
                    <li class="nav-item"><a class="nav-link" id="invest-tab" data-bs-toggle="tab" href="#invest" role="tab" aria-controls="invest">Invest</a></li>
                </ul>
                <div class="tab-content" id="search_tab_content">
                    <div class="tab-pane fade show active" id="sell" role="tabpanel">
                        <form class="search-form">
                            <div class="row justify-content-center align-items-end">
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Looking For</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property type</option>
                                            <option value="1">Multifamily</option>
                                            <option value="2">Detached house</option>
                                            <option value="3">Industrial</option>
                                            <option value="4">Townhouse</option>
                                            <option value="5">Apartment</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <select class="form-select form-control">
                                            <option selected="">All cities</option>
                                            <option value="1">Liverpool</option>
                                            <option value="2">Bristol</option>
                                            <option value="3">Nottingham</option>
                                            <option value="4">Leicester</option>
                                            <option value="5">Coventry</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Your Price</label>
                                        <input type="text" class="form-control" placeholder="Max price"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Min Lot size</label>
                                        <input type="text" class="form-control" placeholder="Property lot size"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property status</option>
                                            <option value="1">Active (55)</option>
                                            <option value="2">Open House (65)</option>
                                            <option value="3">Hot Offer (45)</option>
                                            <option value="4">Sold (78)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="button-group-list">
                                        <button type="submit" class="search-btn">
                                            <i class="ri-search-2-line"></i>
                                        </button>
                                        <button type="submit" class="reset-search-btn">
                                            <i class="ri-refresh-line"></i>
                                            Reset Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="rent" role="tabpanel">
                        <form class="search-form">
                            <div class="row justify-content-center align-items-end">
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Looking For</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property type</option>
                                            <option value="1">Multifamily</option>
                                            <option value="2">Detached house</option>
                                            <option value="3">Industrial</option>
                                            <option value="4">Townhouse</option>
                                            <option value="5">Apartment</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <select class="form-select form-control">
                                            <option selected="">All cities</option>
                                            <option value="1">Liverpool</option>
                                            <option value="2">Bristol</option>
                                            <option value="3">Nottingham</option>
                                            <option value="4">Leicester</option>
                                            <option value="5">Coventry</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Your Price</label>
                                        <input type="text" class="form-control" placeholder="Max price"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Min Lot size</label>
                                        <input type="text" class="form-control" placeholder="Property lot size"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property status</option>
                                            <option value="1">Active (55)</option>
                                            <option value="2">Open House (65)</option>
                                            <option value="3">Hot Offer (45)</option>
                                            <option value="4">Sold (78)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="button-group-list">
                                        <button type="submit" class="search-btn">
                                            <i class="ri-search-2-line"></i>
                                        </button>
                                        <button type="submit" class="reset-search-btn">
                                            <i class="ri-refresh-line"></i>
                                            Reset Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="invest" role="tabpanel">
                        <form class="search-form">
                            <div class="row justify-content-center align-items-end">
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Looking For</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property type</option>
                                            <option value="1">Multifamily</option>
                                            <option value="2">Detached house</option>
                                            <option value="3">Industrial</option>
                                            <option value="4">Townhouse</option>
                                            <option value="5">Apartment</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <select class="form-select form-control">
                                            <option selected="">All cities</option>
                                            <option value="1">Liverpool</option>
                                            <option value="2">Bristol</option>
                                            <option value="3">Nottingham</option>
                                            <option value="4">Leicester</option>
                                            <option value="5">Coventry</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Your Price</label>
                                        <input type="text" class="form-control" placeholder="Max price"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Min Lot size</label>
                                        <input type="text" class="form-control" placeholder="Property lot size"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-select form-control">
                                            <option selected="">Property status</option>
                                            <option value="1">Active (55)</option>
                                            <option value="2">Open House (65)</option>
                                            <option value="3">Hot Offer (45)</option>
                                            <option value="4">Sold (78)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-6">
                                    <div class="button-group-list">
                                        <button type="submit" class="search-btn">
                                            <i class="ri-search-2-line"></i>
                                        </button>
                                        <button type="submit" class="reset-search-btn">
                                            <i class="ri-refresh-line"></i>
                                            Reset Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="properties-grid-box">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <p>Showing 1-8 Of 27 Results</p>
                    </div>
                    <div class="col-lg-5 col-md-6">
                        <div class="d-flex align-items-center justify-content-end">
                            <select class="form-select">
                                <option selected="">Recommend</option>
                                <option value="1">Sort by newest</option>
                                <option value="3">Sort by latest</option>
                                <option value="1">Sort by popularity</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" data-cues="slideInUp">
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image">
                                    <a href="property-grid.html" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="property-grid.html" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="property-grid.html" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="property-details.html">Industrial Spaces</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                            <img src={parking2} alt="" />

                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="agent-profile.html">Thomas Klarck</a>
                                        </div>
                                        <div class="price">$95,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image bg-two">
                                    <a href="property-grid.html" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="property-grid.html" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="property-grid.html" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="property-details.html">Beach Front Property</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                    <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={parking2} alt="" />
                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="agent-profile.html">Walter White</a>
                                        </div>
                                        <div class="price">$77,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image">
                                    <a href="property-grid.html" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="property-grid.html" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="property-grid.html" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="property-details.html">Industrial Spaces</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                            <img src={parking2} alt="" />

                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="agent-profile.html">Thomas Klarck</a>
                                        </div>
                                        <div class="price">$95,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image bg-two">
                                    <a href="property-grid.html" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="property-grid.html" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="property-grid.html" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="property-details.html">Beach Front Property</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                    <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={parking2} alt="" />
                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="agent-profile.html">Walter White</a>
                                        </div>
                                        <div class="price">$77,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image">
                                    <a href="property-grid.html" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="property-grid.html" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="property-grid.html" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="property-details.html">Industrial Spaces</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                            <img src={parking2} alt="" />

                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="agent-profile.html">Thomas Klarck</a>
                                        </div>
                                        <div class="price">$95,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="properties-inner-card with-wrap-color">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="properties-image bg-two">
                                    <a href="property-grid.html" class="featured-btn">Featured</a>
                                    <div class="media">
                                        <span><i class="ri-vidicon-fill"></i></span>
                                        <span><i class="ri-image-line"></i>5</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div class="properties-content">
                                    <div class="info">
                                        <ul class="link-list">
                                            <li>
                                                <a href="property-grid.html" class="link-btn">Apartment</a>
                                            </li>
                                            <li>
                                                <a href="property-grid.html" class="link-btn">For Sale</a>
                                            </li>
                                        </ul>
                                        <ul class="group-info">
                                            <li>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-share-line"></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li>
                                                            <a href="https://www.facebook.com/" target="_blank">
                                                                <i class="ri-facebook-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://twitter.com/" target="_blank">
                                                                <i class="ri-twitter-x-line"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/" target="_blank">
                                                                <i class="ri-instagram-fill"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://bd.linkedin.com/" target="_blank">
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Add To Favorites">
                                                    <i class="ri-heart-line"></i>
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                                                    <i class="ri-arrow-left-right-line"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="title">
                                        <h3>
                                            <a href="property-details.html">Beach Front Property</a>
                                        </h3>
                                        <span>194 Mercer Street, NY 10012, USA</span>
                                    </div>
                                    <ul class="info-list">
                                        <li>
                                            <div class="icon">
                                                    <img src={bed2} alt="" />
                                            </div>
                                            <span>6</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={bathroom2} alt="" />
                                            </div>
                                            <span>4</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={parking2} alt="" />
                                            </div>
                                            <span>1</span>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src={area2} alt="" />
                                            </div>
                                            <span>3250</span>
                                        </li>
                                    </ul>
                                    <div class="price-and-user">
                                        <div class="user">
                                            <img src={user2} alt="" />
                                            <a href="agent-profile.html">Walter White</a>
                                        </div>
                                        <div class="price">$77,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
    

    
    <div class="subscribe-wrap-area">
        <div class="container" data-cues="slideInUp">
            <div class="subscribe-wrap-inner-area">
                <div class="subscribe-content">
                    <h2>Subscribe To Our Newsletter</h2>
                    <form class="subscribe-form">
                        <input type="search" class="form-control" placeholder="Enter your email"/>
                        <button type="submit" class="default-btn">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
    <footer class="footer-area pt-120">
      <div class="container">
          <div class="row justify-content-center" data-cues="slideInUp">
              <div class="col-xl-3 col-md-12">
                  <div class="single-footer-widget pe-3">
                      <div class="widget-logo">
                          <a href="index.html">
                               {/* <img src="images/logo2.png" alt="logo2"> */}
                              <b class="text-white">DREAMKOTHI</b>
                          </a>
                      </div>
                      <p>Lorem ipsum dolor sit amet, elit dollar consectetur adipiscing elit. Diam lectus purus ultricies neque.</p>
                      <div class="widget-social">
                          <a href="https://www.facebook.com/" target="_blank">
                              <i class="ri-facebook-fill"></i>
                          </a>
                          <a href="https://twitter.com/" target="_blank">
                              <i class="ri-twitter-x-line"></i>
                          </a>
                          <a href="https://www.instagram.com/" target="_blank">
                              <i class="ri-instagram-fill"></i>
                          </a>
                          <a href="https://bd.linkedin.com/" target="_blank">
                              <i class="ri-linkedin-fill"></i>
                          </a>
                          <a href="https://www.youtube.com/" target="_blank">
                              <i class="ri-youtube-line"></i>
                          </a>
                          <a href="https://www.pinterest.com/" target="_blank">
                              <i class="ri-pinterest-line"></i>
                          </a>
                      </div>
                  </div>
              </div>
              <div class="col-xl-9 col-md-12">
                  <div class="row justify-content-center" data-cues="slideInUp">
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Company</h3>
                              <ul class="custom-links">
                                  <li><a href="about-us.html">About Us</a></li>
                                  <li><a href="contact-us.html">Contact Us</a></li>
                                  <li><a href="customers-review.html">Our Reviews</a></li>
                                  <li><a href="terms-conditions.html">Terms &amp; Conditions</a></li>
                                  <li><a href="privacy-policy.html">Privacy Policy</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Resources</h3>
                              <ul class="custom-links">
                                  <li><a href="property-grid.html">Apartments</a></li>
                                  <li><a href="property-grid.html">Villa</a></li>
                                  <li><a href="property-grid.html">Sell or Buy</a></li>
                                  <li><a href="property-grid.html">New Apartment</a></li>
                                  <li><a href="agents.html">Our Agents</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget ps-3">
                              <h3>Quick Links</h3>
                              <ul class="custom-links">
                                  <li><a href="pricing.html">Pricing</a></li>
                                  <li><a href="what-we-do.html">What We Do</a></li>
                                  <li><a href="customers-review.html">Testimonial</a></li>
                                  <li><a href="blog-grid.html">Blog</a></li>
                                  <li><a href="neighborhood.html">Neighborhood</a></li>
                              </ul>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-6">
                          <div class="single-footer-widget">
                              <h3>Contact Info</h3>
                              <ul class="info-list">
                                  <li>
                                      <span>Address: </span>
                                      <a href="https://maps.app.goo.gl/JHGxiX1hVZQEDi1B6">
                                      SHOP NO S-45 JANAKPURI COLONY NEAR HATHOJ STAND,JHOTWARA, JAIPUR, RAJASTHAN - 302012
                                      </a>
                                       
                                  </li>
                                  <li>
                                      <span>Email: </span> 
                                      <a href="mailto:dreamkothi@gmail.com">dreamkothi@gmail.com</a>
                                  </li>
                                  <li>
                                      <span>Phone:</span>
                                      <a href="tel:+91 9694949481">+91 9694949481</a>
                                  </li>
                                 
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="copyright-area">
              <p>
                  Copyright <span>Dreamkothi</span> <a href="https://envytheme.com/" target="_blank"></a>
              </p>
          </div>
      </div>
  </footer>
    
    <button type="button" id="back-to-top" class="position-fixed text-center border-0 p-0">
        <i class="ri-arrow-up-s-line"></i>
    </button>
    
    
    
    </mydiv>
)



}

export default AllProperty;